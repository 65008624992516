<!--
居家监护
-->
<template>
  <div class="mainBox">
    <div class="shipTitle">
      <div class="changTitle">
        <div
          class="changeItme"
          :class="changeIndex == 1 ? 'changeItmeActive' : ''"
          @click="changeTitleIndex(1, 'A300030092100000')"
        >
          远程照护服务
        </div>
        <div
          class="changeItme"
          :class="changeIndex == 2 ? 'changeItmeActive' : ''"
          @click="changeTitleIndex(2, 'A300030091800000')"
        >
          居家服务
        </div>
        <div
          class="changeItme"
          :class="changeIndex == 3 ? 'changeItmeActive' : ''"
          @click="changeTitleIndex(3, 'A300030091900000')"
        >
          集中服务
        </div>
      </div>
      <div class="timeBox">
        <el-date-picker
          :clearable="false"
          v-model="timeData"
          @change="timeChange"
          type="daterange"
          placeholder="选择日期"
          class="timeBox"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
    </div>
    <!--健康监护-->
    <div class="shipMain">
      <div class="healthBox">
        <div class="shipNav">
          <img
            src="@/assets/img/homeGuardianship/pressTitle.png"
            alt=""
            class="titleIcon"
          />
          <span class="titleName">服务订单</span>
        </div>
      </div>
      <!-- table -->
      <div class="tableBox">
        <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          :row-class-name="tableRowClassName"
        >
          <el-table-column prop="id" label="档案编号"> </el-table-column>
          <el-table-column prop="userrName" label="姓名"> </el-table-column>

          <el-table-column prop="moblie" label="手机号码"> </el-table-column>
          <!-- <el-table-column prop="address" label="服务包"> </el-table-column> -->
          <el-table-column prop="" label="服务项目">
            <template slot-scope="scope">
              {{ scope.row.details[0].servItemName || "" }}
            </template>
          </el-table-column>
          <el-table-column prop="appoTime" label="时间"> </el-table-column>
          <el-table-column label="订单状态">
            <template slot-scope="scope">
              <div
                class="statusBox statusBoxColor1"
                v-if="scope.row.orderState == 0"
              >
                未支付
              </div>
              <div
                class="statusBox statusBoxColor2"
                v-if="scope.row.orderState == 1"
              >
                已支付
              </div>
              <div
                class="statusBox statusBoxColor3"
                v-if="scope.row.orderState == 2"
              >
                已接单
              </div>
              <div
                class="statusBox statusBoxColor1"
                v-if="scope.row.orderState == 3"
              >
                已服务
              </div>
              <div
                class="statusBox statusBoxColor2"
                v-if="scope.row.orderState == 4"
              >
                已完成
              </div>
              <div
                class="statusBox statusBoxColor3"
                v-if="scope.row.orderState == 5"
              >
                已取消
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagBox">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="sendData.pageIndex"
            :page-size="10"
            layout="total, prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetOrder } from "../../api/homeGuardianship";
export default {
  components: {},
  data() {
    return {
      timeData: "",
      loading: true,
      changeIndex: 1,
      sendData: {
        //  servMainID: "A300030092100000",
        start: "",
        end: "",
        pageIndex: 1,
        pageSize: 10,
      },
      tableData: [],
      total: 0,
    };
  },
  created() {
    this.getTable();
  },

  mounted: function () {},
  destroyed: function () {},
  computed: {},
  watch: {},
  methods: {
    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "double-row";
      }
      return "";
    },
    changeTitleIndex(index) {
      this.changeIndex = index;
      this.timeData = [];
      this.loading = true;
      this.getTable();
    },
    handleCurrentChange() {
      this.getTable();
    },
    timeChange(val) {
      if (val) {
        this.sendData.start = val[0];
        this.sendData.end = val[1];
        this.getTable();
      }
    },
    getTable() {
      GetOrder(this.sendData).then((res) => {
        this.loading = false;
        let data = res.result.dataList;
        this.tableData = data;
        this.total = res.result.totalRecordCount;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.statusBox {
  min-width: 75px;
  min-height: 26px;
  border-radius: 13px 13px 13px 13px;
  line-height: 26px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  display: inline-block;
}
.statusBoxColor1 {
  border: 1px solid #fd500a;
  color: #fd500a;
}
.statusBoxColor2 {
  border: 1px solid #03ad82;
  color: #03ad82;
}
.statusBoxColor3 {
  border: 1px solid #ffc800;
  color: #ffc800;
}
.timeBox {
  margin-bottom: 10px;
  /deep/.el-input__inner {
    background: none !important;
    border-color: #2f69e6 !important;
    color: #2f69e6 !important;
  }
  /deep/.el-input__inner::placeholder {
    color: #2f69e6 !important;
  }
  /deep/.el-input__prefix {
    color: #2f69e6;
  }
  /deep/ .el-range-input {
    background: none !important;
  }
}
</style>
